import { graphql } from 'gatsby'
import React from 'react'

import { Layout, Section, SEO } from '../components'
import { SupporterTeaser, SupporterTeaserView } from '../components/supporters'
import { contentColorMap } from '../styles'
import { Markdown, Payload } from '../types'

interface SupportersPageProps {
  data: Payload<Markdown>
  location: Location
}

export const SupportersPage = ({ location: { pathname }, data: { res } }: SupportersPageProps) => {
  const pageColor = contentColorMap.supporters
  const title = 'Unterstützer*innen'
  return (
    <Layout currPath={pathname} pageColor={pageColor}>
      <SEO title={title} />
      <Section renderBg={true} color={pageColor} colorIndex={0} headerOffset={true} index={0}>
        <SupporterTeaserView title={title}>
          {res.edges.map(
            (
              {
                node: {
                  fields: { slug },
                  frontmatter: { summary, image, title, roles }
                }
              },
              i
            ) => (
              <SupporterTeaser
                key={i}
                image={image!}
                path={slug!}
                title={title!}
                suffix={roles}
                summary={summary!}
                index={i}
              />
            )
          )}
        </SupporterTeaserView>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query Supporters {
    res: allMarkdownRemark(sort: {order: ASC, fields: fileAbsolutePath}, filter: {fileAbsolutePath: {regex: "/(supporters)/.*\\.md$/"}, fields: { hidden: { eq: false } } }) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            summary
            title
            roles
            image {
              mobile: childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 700, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
              desktop: childImageSharp {
                fluid(maxWidth: 600, maxHeight: 450, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SupportersPage
